import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

export default class PrivacyPolicy extends React.Component {
  render() {
    return (
      <Layout title="Privacy Policy">
        <Seo title="Privacy Policy"/>
        <div className="container">
          <div className="row">
            <section className="col">
              <h2 className="mb-3">Privacy Policy</h2>

              <h3>Introduction</h3>

              <p>Hope for Native America, a division of Ron Hutchcraft Ministries, is committed to helping maintain your privacy. We deeply appreciate that you’ve trusted us with personal information and we are committed to treating it confidentially. Below are specific guidelines describing how your information is used.</p>

              <h3>ECFA</h3>

              <p>Hope for Native America, a division of Ron Hutchcraft Ministries, is a member in good standing of the Evangelical Council for Financial Accountability (ECFA). The purpose of the ECFA is to help Christ-centered evangelical nonprofit organizations earn the public’s trust through their ethical practices and financial accountability. Our membership in good standing is tangible evidence that Hope for Native America, a division of Ron Hutchcraft Ministries, adheres to the highest standards of financial integrity and Christian ethics.</p>

              <h3>How We Collect Your Information</h3>

              <p>Hope for Native America, a division of Ron Hutchcraft Ministries, does not collect personal information without your knowledge. On our websites, you can provide contact information for ministry-related purposes, to order resources, to receive email updates and promotions, or to make ministry contributions. The information that we may collect online includes:</p>

              <ul><li>Contact Information: name, organization, complete address, phone, email address.</li>
                <li>Payment Information: method of payment, credit card or service ID, essential verification information.</li>
                <li>Shipping Information: name, organization, complete shipping address, and phone.</li>
                <li>Promotional Information: how you found us, what prompted your interest and your areas of interest.</li>
                <li>Correspondence Information: suggestions, comments, messages, questions and requests for further correspondence.</li>
                <li>Usage Information: IP address, browser type, operating system, date stamps, etc. (see Logs below).</li>
              </ul><p>Information we may collect at events includes (but is not limited to):</p>

              <ul><li>Names and addresses&nbsp;</li>
                <li>Email addresses&nbsp;</li>
                <li>Phone numbers&nbsp;</li>
              </ul><p>We collect credit card information for purchases and donations.</p>

              <h3>How We Use Your Information</h3>

              <p>Hope for Native America, a division of Ron Hutchcraft Ministries, is extremely careful with the personal information you provide. We will use it to:</p>

              <ul><li>Respond to your correspondence, questions, comments, suggestions, thoughts, opinions, prayer requests, concerns, or ideas to fulfill a resource request.</li>
                <li>Process your request for resources from our online bookstore.</li>
                <li>Contact you to confirm specific requests, deliver email newsletters you request, inform you of new materials and special offers, or provide information we think may be of particular interest to you.</li>
                <li>Continually update, develop, design, and customize the content of our Web site to meet the needs, interests, and requests of our audience.</li>
                <li>Publish comments and testimonials in ministry materials with reference to your name, initials, location, age, etc., provided that permission to do so is provided by you.</li>
                <li>Publish comments and testimonials in ministry materials without reference to your name, initials, location, age, etc., if permission to reference your name has not been provided by you.</li>
                <li>We will always provide a way for you to be removed from our mailing lists.</li>
              </ul><p>Hope for Native America, a division of Ron Hutchcraft Ministries, never sells, rents, leases, or exchanges any personal information with other organizations. Hope for Native America, a division of Ron Hutchcraft Ministries, never sells, rents, leases, or exchanges the identity of its donors to others except as required by law (i.e. 990 tax form).</p>

              <p>Protection of Hope for Native America, a division of Ron Hutchcraft Ministries, and others: We release account and other personal information when we believe release is appropriate to comply with the law; enforce or apply our Conditions of Use and other agreements; or protect the rights, property, or safety of Hope for Native America, a division of Ron Hutchcraft Ministries, our constituents, or others.</p>

              <h3>Refund Policy</h3>

              <p>Donation refunds are granted only in exceptional circumstances. If you have made an error in your donation or if you have not authorized a specific donation, please contact us about a refund within fifteen (15) business days. All donations are final after seven business days.</p>

              <p>To request a refund, email us at <a href="mailto:info@h4na.org">info@h4na.org</a> or call us at (870) 741-2181. Refunds are processed and returned using the original method of payment unless you paid in cash, in which case your donation will be refunded via check. If you made your donation by credit card, your refund will be credited to that same credit card.</p>

              <h3>Security</h3>

              <p>This site has security measures in place to protect the loss, misuse, and alteration of the information under our control. We utilize industry-standard secure server connections during the product ordering and donation process to ensure that your personal data is encrypted and secure. This site does not store information related to credit card transactions (i.e. card numbers, CVV codes, expiration dates, etc.) that are required for donations to, or purchases from Hope for Native America, a division of Ron Hutchcraft Ministries.</p>

              <h3>Logs</h3>

              <p>Hope for Native America, a division of Ron Hutchcraft Ministries, uses log files to track trends, activity of users, and to improve the overall user experience. These log files contain information about what domain you are coming from (i.e., AOL.com, Google.com), what browser software and system software you use, and what pages were visited on our site. This information is utilized by authorized Hope for Native America, a division of Ron Hutchcraft Ministries, personnel only. Any archived information is utilized for statistical purposes only.</p>

              <h3>Cookies</h3>

              <p>Cookies are alphanumeric identifiers that we transfer to your computer’s hard drive through your Web browser to enable our systems to recognize your browser and improve your experience on Hope for Native America, a division of Ron Hutchcraft Ministries, websites.</p>

              <p>The Help portion of the toolbar on most browsers will tell you how to change how your browser handles cookies. However, cookies allow you to take full advantage of our website and the vast majority of others, and we recommend that you leave them turned on.</p>

              <h3>Links</h3>

              <h4>Outbound Links</h4>

              <p>Outbound links are hyperlinks that are owned and controlled by a website which direct visitors to a third-party website. Hope for Native America's, a division of Ron Hutchcraft Ministries, websites may contain outbound links to third party websites that are not owned or controlled by Hope for Native America, a division of Ron Hutchcraft Ministries. We make every effort to link to sites that reflect our calling and commitment to the highest standards of integrity.</p>

              <p>The outbound hyperlinks on this website are provided as an additional resource only and should not be seen as an endorsement, approval, or agreement with any information or resource offered at sites accessed through our site. No inference or assumption should be made and no representation should be inferred that Hope for Native America, a division of Ron Hutchcraft Ministries, is connected with, operates, or controls these websites. The privacy and data collection practices on third-party websites are entirely separate from Hope for Native America, a division of Ron Hutchcraft Ministries, and are not covered by this privacy policy.</p>

              <p>Hope for Native America, a division of Ron Hutchcraft Ministries, has no control over and assumes no responsibility for the content, privacy policies, or practices of any third-party website. In addition, Hope for Native America, a division of Ron Hutchcraft Ministries, does not and cannot censor or edit the content of any third-party website. By using our website, you express the release of Hope for Native America, a division of Ron Hutchcraft Ministries, from any and all liability arising from your use of any third-party website. Accordingly, we encourage you, when you leave the Hope for Native America, a division of Ron Hutchcraft Ministries, website, to read the terms and conditions and privacy policy of each other website you visit.</p>

              <h4>Inbound Links</h4>

              <p>Inbound links are hyperlinks from a website that is owned and controlled by a third-party which direct visitors to another website. Third party websites, that are not owned or controlled by Hope for Native America, a division of Ron Hutchcraft Ministries, may contain inbound links to Ron Hutchcraft Ministries' websites. The presence of inbound links from third-party websites should not be seen as an endorsement, approval, or agreement with the sites that link to Hope for Native America, a division of Ron Hutchcraft Ministries, websites. No inference or assumption should be made and no representation should be inferred that Hope for Native America, a division of Ron Hutchcraft Ministries, is connected with, operates, or controls these websites. The privacy and data collection practices on websites that link to Hope for Native America, a division of Ron Hutchcraft Ministries, websites are entirely separate from Hope for Native America, a division of Ron Hutchcraft Ministries, and are not covered by this privacy policy.</p>

              <h4>Hope for Native America, a division of Ron Hutchcraft Ministries, Website Linking Policy</h4>

              <p>Hope for Native America, a division of Ron Hutchcraft Ministries, uses hyperlinks to provide our website users easy access to helpful resources or online materials. When linking to Hope for Native America, a division of Ron Hutchcraft Ministries, websites, please observe the following practices as a courtesy, or in some cases a legal necessity:</p>

              <p>Please:</p>

              <p>Observe all copyright laws. All requests for copyright information must be submitted to Ron Hutchcraft Ministries in writing. Link to our homepage using the following text only: "Ron Hutchcraft Ministries, Inc." Link directly to Ron Hutchcraft Ministries' website content. When using the name of the article or resource as a hyperlink, the link should go directly to the article or resource, not to our homepage. The link should also indicate that they are being directed to a Ron Hutchcraft Ministries website. Example: Knowing What It Is To Be Broken from Ron Hutchcraft Ministries, Inc.</p>

              <p>Please do not:</p>

              <p>Use a Hope for Native America, a division of Ron Hutchcraft Ministries, article or resource title to link anywhere but directly to the corresponding Hope for Native America, a division of Ron Hutchcraft Ministries, article or resource. Attribute a link on your site to Hope for Native America, a division of Ron Hutchcraft Ministries, and then link to somewhere else. Infer that Hope for Native America, a division of Ron Hutchcraft Ministries, has in any way endorsed or approved or is in agreement with the content presented on your website. Frame Hope for Native America, a division of Ron Hutchcraft Ministries, website content or resources on your website, or otherwise present the content as your own. Use any images from a Hope for Native America, a division of Ron Hutchcraft Ministries, website on your site. If you have any questions about this policy, please contact us.</p>

              <p>Permission to link to Hope for Native America, a division of Ron Hutchcraft Ministries, websites is automatically granted if the above Website Linking Policy is followed.</p>

              <h3>Maintenance of Information</h3>

              <p>If your personally identifiable information changes (such as zip code, phone number, e-mail or postal address), or if you no longer desire to receive email updates etc., we provide a way to correct, update, or delete/deactivate such information.</p>

              <p>To update your physical/mailing address or your email address, contact Hope for Native America, a division of Ron Hutchcraft Ministries, by mail or phone at the number provided below.</p>

              <p>To remove yourself from any email lists, click the "Manage my subscription" link that is provided in every email you receive from us.</p>

              <h3>Changes to this Statement</h3>

              <p>Hope for Native America, a division of Ron Hutchcraft Ministries, reserves the right to make changes to this privacy statement and its associated policies at any time and requests that you review this statement periodically for updates.</p>

              <p>This Privacy Statement was last updated on May 23, 2017.</p>

              <h3>Contact Information</h3>

              <p>If you have questions or concerns about any information on this page, please contact Hope for Native America, a division of Ron Hutchcraft Ministries, online or by phone at (870) 741-2181.</p>

              <p>Thank you!</p>              
            </section>
          </div>
        </div>
      </Layout>
    )
  }
}

